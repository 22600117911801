html {
    overflow-y: scroll;
}

body {
    color: #fefefe;
    font-family: 'Poppins', sans-serif;
}

p{
    margin-top: 20px;
    font-size: 1.1rem;
}

img {
    max-width: 100%;
}

a {
    color: #1165eb;
    font-weight: bold;
}

a:hover{
    color: #ccc;
}

#root {
    background-color: #111;
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
}

.particle-canvas {
    width: 100%;
    height: 86vh;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 0;
}

.main {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    margin-bottom: 100px;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}

.site-title {
    text-decoration: none;
    color: #f9f9f9;
}

.pos-title{
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 25px;
}


.header {
    background-color: #181818;
}

.navigation {
    display: flex;
    justify-content: space-between;
}

.navbar{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-dark {
    background-color: #181818 !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,0.9);
    letter-spacing: 0.5px;
}

.page-title {
    text-align: center;
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 20px;
}
.about-container{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.about{
    padding-top: 30px;
    min-width: 55%;
}

.about-title {
    margin-bottom: 20px;
}
.about-title p{
    font-size: 3rem;
    line-height: 3.5rem;
}

.about-text {
    margin-top: 0;
    font-size: 1.2rem;
}

.about-img{
    margin-top: 45px;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
}

.about-img > img{
    width: 45%;
}

.portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.portfolio .project {
    width: 495px;
}

.project > a {
    text-decoration: none;
}

.project-image img {
    width: 85%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 5px 4px #555;
    transition: filter 0.4s;
}

.project-image img:hover {
    box-shadow: 0 0 6px 5px #555;
    -webkit-filter: brightness(0.8);
    filter: brightness(0.8);
}

.project-title {
    margin-top: 20px;
    color: #fff;
    font-weight: bold;
}

.project-category {
    margin-bottom: 50px;
    color: #fff;
}
.port-img{
    width:20%;
    margin-top: 50px;
}
.navigation-sub {
    padding-top: 30px;
}

a.nav-item:hover {
    color: #aaa;
}


.footer {
    background-color: #181818;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 15px 0 15px;
}

.footer-content {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: flex-start;
}

.MuiButton-root:hover{
    background-color: #1F50FF !important;
    color: #fff !important;
}
.blue-button:hover{
    background-color: transparent !important;
    color: #fff !important;
}

.bl-card{
    width: 205px;
    border: 1px solid #555;
    box-shadow: 0 0 3px 2px #444;
    background-color: #282828;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    margin: 20px;
    padding: 20px;
    color: #fff;
    transition: background-color 0.3s;
    transition: box-shadow 0.3s;
}

.bl-card:hover{
    color: white;
    box-shadow: 0 0 4px 4px #444;
    text-decoration: none;
    background-color: #242424;
}
.bl-card-blue{
    border-top: 15px solid #1F50FF;
}

.bl-card-blue:hover{
    border-top: 15px solid #0637E6;
}

.bl-card-green{
    border-top: 15px solid #53960B;
}

.bl-card-green:hover{
    border-top: 15px solid #3A7D00;
}

.site-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}
.contact-info{
    display: flex;
    justify-content: center;
}
.contact-info > div{
    margin: 20px;
}
.contact-img{
    width: 20%;
    margin-top: 70px;
    margin-bottom: 80px;
}

.email img, .linkedin img {
    transition: filter 0.4s;
}

.email img:hover, .linkedin img:hover{
    -webkit-filter: brightness(0.7);
    filter: brightness(0.7);
}

.skills-img img {
    margin-top: 50px;
    width: 22%;
}

.white-hr{
    border-color: #aaa;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}


@media screen and (min-width: 991px) {
    .navigation-sub>.nav-item.current-menu-item {
        border-top: 2px solid #2f75e4;
        margin-top: 0px;
    }
    .navigation-sub>.nav-item.current-menu-item>a {
        margin-top: -2px;
    }
    .navigation-sub>.nav-item {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        overflow: hidden;
        margin-left: 10px;
        margin-right: 10px;
        color: #f9f9f9;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
        padding-top: 5px;
    }
    .navigation-sub>.nav-item:not(.current-menu-item):before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 100%;
        top: 0;
        background-color: #2f75e4;
        height: 2px;
        -webkit-transition-property: right;
        transition-property: right;
        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;
        -webkit-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
    }
    .navigation-sub>.nav-item:not(.current-menu-item):hover:before {
        right: 0;
    }
    
}

@media screen and (max-width: 767px){
    .page-title{
        font-size: 1.8rem;
    }
    .about-title {
        min-height: 112px;
    }
    .pos-title{
        font-size: 1.4rem;
    }
    .about-img{
        margin-top: 65px;
    }
    .about-img > img{
        width: 65%;
    }
    .port-img{
        width: 75%;
    }
    .skills-img img {
        margin-top: 30px;
        width: 35%;
    }
    .bl-card{
        width: 200px;
        min-height: 225px;
    }
    .main{
        margin-left: 5%;
        margin-right: 5%;
    }
    .navbar {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .makeStyles-bar-1 {
        margin-top: 0 !important;
        margin-bottom: 30px;
    }
    .contact-img{
        width: 40%;
    }
}

@media screen and (max-width: 374px){
    .navbar-brand{
        font-size: 1rem;
    }
    .navbar-brand img{
        width: 35px !important;
    }
}

.MuiLinearProgress-bar1Determinate {
    transition: transform 1s ease-in-out !important;
}
.html_bar > .MuiLinearProgress-barColorPrimary {
    background-color: #1F50FF !important;
}
.css_bar > .MuiLinearProgress-barColorPrimary {
    background-color: #41701f !important;
}
.js_bar > .MuiLinearProgress-barColorPrimary {
    background-color: #CF5F0A !important;
}
.react_bar > .MuiLinearProgress-barColorPrimary {
    background-color: #088fa7 !important;
}

.portfolio a:hover{
    text-decoration: none;
}

.vertical-timeline-element-content.bounce-in {
    background-color: #252525;
}
.vertical-timeline-element-date {
    text-align: left;
}

h4.vertical-timeline-element-subtitle {
    font-size: 1.1rem;
    padding-top: 10px;
    text-transform: uppercase;
}

h3.vertical-timeline-element-title {
    font-size: 1.6rem;
}

.vertical-timeline-element:last-child > .vertical-timeline-element-content {
    display: none;
} 
.experience {
    margin-bottom: 60px;
}

.vertical-timeline-element-content {
    box-shadow: 0;
    border-bottom: 3px solid #fff;
}

@media screen and (max-width:475px){
    .portfolio-card{
        width: 300px !important;
    }
}

